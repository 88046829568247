export const MODE: string = process.env.REACT_APP_MODE || 'local';

let apiLink = '';

switch (process.env.REACT_APP_MODE) {
  case 'prod':
    apiLink = 'https://beta-api.substrata.me';
    break;
  case 'stage':
    apiLink = 'https://staging.beta-api.substrata.me';
    break;
  default:
    apiLink = 'http://localhost:8002';
    break;
}

export const API_LINK: string = process.env.REACT_APP_API_LINK || apiLink;

let signupLink = '';

switch (process.env.REACT_APP_MODE) {
  case 'prod':
    signupLink = 'https://signup.substrata.me';
    break;
  case 'stage':
    signupLink = 'https://staging.signup.substrata.me';
    break;
  default:
    signupLink = 'http://localhost:3002';
    break;
}

export const SIGNUP_LINK: string =
  process.env.REACT_APP_SIGNUP_URL || signupLink;

let accountUrl = '';

switch (MODE) {
  case 'prod':
    accountUrl = 'https://account.substrata.me';
    break;
  case 'stage':
    accountUrl = 'https://staging.account.substrata.me';
    break;
  default:
    accountUrl = 'http://localhost:3003';
    break;
}

export const ACCOUNT_URL: string =
  process.env.REACT_APP_ACCOUNT_URL || accountUrl;

let superAccountUrl = '';

switch (MODE) {
  case 'prod':
    superAccountUrl = 'https://admin.substrata.me';
    break;
  case 'stage':
    superAccountUrl = 'https://staging.admin.substrata.me';
    break;
  default:
    superAccountUrl = 'http://localhost:3004';
    break;
}

export const SUPER_ACCOUNT_URL: string =
  process.env.REACT_APP_SUPER_ACCOUNT_URL || superAccountUrl;

export const FIREBASE_CONFIG = {
  apiKey: '',
  authDomain: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
  appId: '',
};

/**
 * Firebase-related APIs use API keys only to identify the Firebase project or app, not for authorization to call the API (like some other APIs allow).
 * https://firebase.google.com/docs/projects/api-keys
 */
switch (MODE) {
  case 'prod': {
    // TODO: Add production firebase config
    FIREBASE_CONFIG.apiKey = '';
    FIREBASE_CONFIG.authDomain = '';
    FIREBASE_CONFIG.projectId = '';
    FIREBASE_CONFIG.storageBucket = '';
    FIREBASE_CONFIG.messagingSenderId = '';
    FIREBASE_CONFIG.appId = '';
    break;
  }
  case 'stage': {
    FIREBASE_CONFIG.apiKey = 'AIzaSyArGgBlAzgAFjp04kvdvH_WEeFGZhf5IMY';
    FIREBASE_CONFIG.authDomain = 'staging.user-auth.substrata.me';
    FIREBASE_CONFIG.projectId = 'substrata-ops';
    FIREBASE_CONFIG.storageBucket = 'substrata-ops.appspot.com';
    FIREBASE_CONFIG.messagingSenderId = '155879858431';
    FIREBASE_CONFIG.appId = '1:155879858431:web:ea7a839afd07c0bc786cca';
    break;
  }
  default: {
    FIREBASE_CONFIG.apiKey = 'AIzaSyDFnc6WtQraep8K2F0ux8nqyi4kOrMNrg8';
    FIREBASE_CONFIG.authDomain = 'substrata-cto.firebaseapp.com';
    FIREBASE_CONFIG.projectId = 'substrata-cto';
    FIREBASE_CONFIG.storageBucket = 'substrata-cto.appspot.com';
    FIREBASE_CONFIG.messagingSenderId = '993137959493';
    FIREBASE_CONFIG.appId = '1:993137959493:web:0b002592b7dc26f9f1792c';
    break;
  }
}

export const FIREBASE_APP_NAME = 'adminApp';

export const BROADCAST_CHANNEL_NAME = 'superAdmin';
